/* Global Styles */
body {
    font-family: 'Arial', sans-serif;
    background-color: #f4f7f6;
    margin: 0;
    padding: 0;
  }
  
  .container {
    max-width: 500px;
    margin: 0 auto;
    padding: 16px;
  }
  
  h1, h2 {
    margin: 0;
    padding: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  h1 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
  }
  
  h2 {
    font-size: 1.5rem;
    color: #444;
  }
  
  /* Error Alert */
  .alert {
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    color: #721c24;
    padding: 16px;
    border-radius: 4px;
    margin-bottom: 16px;
  }
  
  .alert strong {
    font-weight: bold;
  }
  
  /* Form Elements */
  label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: #333;
  }
  
  select, input[type="password"], input[type="number"], button {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 20px;
  }
  
  select:focus, input[type="password"]:focus, input[type="number"]:focus {
    border-color: #007bff;
    outline: none;
  }
  
  button {
    background-color: #007bff;
    color: white;
    font-size: 1rem;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  /* Quote Box */
  .quote-box {
    background-color: #ffffff;
    border: 1px solid #ddd;
    padding: 16px;
    border-radius: 4px;
    margin-top: 16px;
  }
  
  .quote-box p {
    margin: 0;
    padding: 8px 0;
  }
  
  /* Trade History */
  ul.trade-history {
      list-style-type: none;
      padding-left: 0;
      font-family: monospace;
  }

  ul.trade-history li {
      margin-bottom: 5px;
      padding: 5px 0;
      border-bottom: 1px solid #333;
  }

  ul.trade-history li:last-child {
      border-bottom: none;
  }

  .quote {
    font-family: monospace;
  }

  .spinner {
    border: 4px solid #f3f3f3; /* Light gray */
    border-top: 4px solid #007bff; /* Blue */
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
    margin: auto auto;
    margin-bottom: 33px;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
